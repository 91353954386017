import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import {base_en} from "./trans/en";
import {base_it} from "./trans/it";
import {base_fr} from "./trans/fr";

const detection_opt =  {
  order: ['cookie','path','navigator'],
  lookupFromPathIndex: 0,
  caches: ['localStorage', 'cookie'],
  cookieMinutes: 10,
  cookieDomain: 'atlasparcaventure_lng',  
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn

  //.use(Backend)   // learn more: https://github.com/i18next/i18next-http-backend
  .use(LanguageDetector)   // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)   // pass the i18n instance to react-i18next.

  .init({ // for all options read: https://www.i18next.com/overview/configuration-options
    fallbackLng: 'en',
    supportedLngs: ['en','fr','it'],
    debug: true,
    detection:  detection_opt,
    interpolation: { escapeValue: false },
	//backend: { loadPath: '/lng/{{lng}}.json' },	
    resources: {
        en: {base: base_en},
        it: {base: base_it},
        fr: {base: base_fr},
    }    
  });


export default i18n;