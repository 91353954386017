import React, { Component } from 'react';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

function PartMeteo(props) {
    const { t, i18n  } = useTranslation('base');
    const n = t('park_name'); //<b></b>;
    //{props.pageTxtBlock.idclassblock}
  return (
      <span></span>
    );
  }

export default PartMeteo;

//<a class="weatherwidget-io" href="https://forecast7.com/fr/33d52n5d11/ifran/" data-label_1="IFRANE" data-label_2="Parc" data-font="Arial Black" data-days="5" data-theme="marine" >IFRANE Parc</a