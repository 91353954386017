import React, { Component }  from 'react';

const DevBy = () => {
  return (
      <div className="devby">
        <div >
          Power by <a href="https://www.tratto.com" target="_blank">TRATTO web solutions</a>
        </div>
      </div>
  );
};
export default DevBy;