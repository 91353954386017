import React, { Component } from 'react';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";


function Part01(props) {
    const { t, i18n  } = useTranslation('base');
    const n = t('park_name'); //<b></b>;
    
  document.USE = props.pageTxtBlock;
  return (
      <section className="sez01" style={{backgroundImage:'url("' + process.env.PUBLIC_URL + '/img/bg4.jpg")'}} >
        <div>
          <div><img  src={process.env.REACT_APP_MEDIAURL +  props.parkLogo + "/?" + process.env.REACT_APP_ACCESSTOKEN + "&width=400"} border="0" id="logo" className="animate__animated animate__bounceIn animate__delay-1s animate__fast"/></div>
          <div className="txt animate__animated animate__fadeIn animate__delay-2s">
            {parse(props.pageTxtBlock.page_area.find((e) => e.key == 'BODY').txt)}
          </div>  
        </div>
        </section>
    );
  }

export default Part01;