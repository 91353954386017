import React, { Component } from 'react';
//import ScrollAnim from 'rc-scroll-anim';
//https://motion.ant.design/components/scroll-anim#components-scroll-anim-demo-parallax-timeline
//https://animate.style/
//https://queue-anim.vercel.app/

import Header from './Header';
import Part01 from './Part01';
import Social from './PartSocial';
import Meteo from './PartMeteo';
import Mappa from './PartMap';
import Footer from './PartFooter';

class Alllangs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartitems: []
    };
  }
    addItem = (item) => {
      this.setState({
          cartitems : this.state.cartitems.concat([item])
      });
    }
    
    render() {

    return (
            <>
            <Header togglemenuClick={()=> this.props.togglemenuClick(this)} socialLinks={this.props.parkInfo.socialnetworks} email={this.props.parkInfo.email} w3w={this.props.parkInfo.txt_multilang[0].what3words}/>
            <Part01 pageTxtBlock={this.props.pageTxtBlocks[0]} parkLogo={this.props.parkInfo.logo}/>
            <Meteo/>
            <Social socialNetworks={this.props.parkInfo.socialnetworks} parkEmail={this.props.parkInfo.email}/>
            <Mappa pnt={this.props.parkInfo.position} parkName={this.props.parkInfo.name}/>
            <Footer txt={this.props.pageTxtBlocks[0].page_area.find((e) => e.key == 'FOOTER').txt} phone={this.props.parkInfo.phone}/>
           </>
          )
  }

};

//  http://umap.openstreetmap.fr/en/map/anonymous-edit/845091:pM1US89RP-Rhhpyjx_uYTCN2w0k


export default Alllangs;
//{items.map(item => <Product key={item.id} product={item} addItem={this.addItem} />)}
/*
              <Part01 pageTxtBlock={this.props.pageTxtBlocks[0]} parkLogo={this.props.parkInfo.logo}/>

*/