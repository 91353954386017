import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

//https://www.npmjs.com/package/react-helmet-async
//{this.props.parkInfo} 

function Meta(props) {
    const { t, i18n  } = useTranslation('base');
    const altLang = i18n.options.supportedLngs.map((l, i) => 
        (l===i18n.language || l==='cimode' ? "" : <link key={l} rel="alternate" hreflang={l} href={"/" + l + "/"} /> )
    );
    const d = i18n.dir();
  
    return (
        <Helmet htmlAttributes={{ lang : i18n.language, "dir" : d }}>
            <title>{props.parkName + ' : : : ' + props.pageInfo.title}</title>
            <meta name="description" content={props.pageInfo.meta_description} />
            <meta name="key" content={props.pageInfo.meta_keywords} />
            {altLang}
        </Helmet>
    );
}

export default Meta;


