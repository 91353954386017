export const base_en = {
  // only in default language -----------------------------------------------------
  park_name: "ATLAS Parc Aventure",
  en_name: 'English',
  it_name: 'Italiano',
  fr_name: 'Français',
  ar_name: 'عربى',
  es_name: 'Español',
  // only in default language -----------------------------------------------------
/*
  meta_tit: "Parc national d'Ifrane",
  meta_key: "Adventure Park,Ifrane,Atlas,Morocco,Nature,Sport,Ecotourism,Albering,Parc Aventure,Rope Course",
  meta_dsc: "The one and only tree climbing park located in the Ifrane National Park in Morocco, in an oasis of nature between majestic cedars and in the company of pretty and irreverent monkeys.",
  home_dsc: {
    part1: "Tree Climbing Parc, Albering, Parc Aventure, Rope Course,  Adventure Park... ",
    part2: "Many names for a single concept: emotions in nature in complete safety!",
  },
  what3words:{
    w1: "shrimp",
    w2: "stinginess",
    w3: "ticking",
  },
*/

};