import React, { Component }  from 'react';
import parse from "html-react-parser";
import { MapContainer } from 'react-leaflet/MapContainer'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Circle } from 'react-leaflet/Circle'
import { Tooltip } from 'react-leaflet/Tooltip'
import 'leaflet/dist/leaflet.css';

const Footer = (props) => {
  const position2 = [51.505, -0.09]
  const position = [props.pnt.coordinates[1], props.pnt.coordinates[0]]
  document.PNT = props.pnt;
  return (
    <div className="sez03">

      <MapContainer center={position} zoom={13} p scrollWheelZoom={false} style={{ height: '50vh' }} position2={'bottomleft'}>
      <TileLayer attribution='' 
      url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
      urld='https://tile.openstreetmap.de/{z}/{x}/{y}.png'
      urlx='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      urly='https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'
      />
  

    <Circle
      center={position}
      pathOptions={{ color: 'red', opacity:'0.5', fillColor: 'red',fillOpacity:'0.8' }}
      radius={100}
    >
    </Circle>
    <Circle
      center={position}
      pathOptions={{ color: 'red' ,fillColor: 'red'}}
      radius={200}
    >
      <Tooltip permanent direction="bottom" offset={[0, 20]} style={{fontWeight:'bold'}}><b>{props.parkName}</b></Tooltip>
    </Circle>

  </MapContainer>


    </div>
     );
};
export default Footer;

/*

https://react-leaflet.js.org/

https://www.youtube.com/watch?v=i9oX1upSKjI&list=PLKmmzLM3RgQVFqnNqn3CI2Cxg4Rd__VbK

      <MapContainer center={position} zoom={13} scrollWheelZoom={false}>

      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
      </MapContainer>


           <div className="sez03">
           <div style={{flex:'100%'}}>
                <iframe width="100%" height="350px" frameBorder="0" allowFullScreen src="//umap.openstreetmap.fr/en/map/atlas-parc-aventure_845091"></iframe>
              </div>
            </div>
*/