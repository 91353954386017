import React, { Component }  from 'react';
import parse from "html-react-parser";
import { OverPack } from 'rc-scroll-anim';
//import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';

//const ScrollPX = ScrollAnim.Parallax;
//const ElementPX = ScrollAnim.Element;
const FirstLetterCharCode = 97; //a


function Part02(props) {
    return (
      <OverPack className="sez02" style={{backgroundImage:'url("' + process.env.PUBLIC_URL + '/img/socialBG.jpg")'}} >
        <QueueAnim key="0" animation={{ opacity: 1 }} type='left'
          interval='200'
          xduration='700'
          xxease="easeInOutQuart"
          >
            {props.socialNetworks.map((sl,i) => 
              (i % 2 === 0 ? <div className="hplink_item" key={String.fromCharCode(FirstLetterCharCode+i)} ><a href={sl.social_link} target="_blank" title={sl.social}><i className={sl.social_icon} aria-hidden="true"></i>{sl.social}</a></div> : "" )
            )}

        </QueueAnim>
        <QueueAnim key="queue" animation={{ opacity: 1 }} type='right'
          interval='200'
          xdelay='0'
          xduration='700'
        >
            {props.socialNetworks.map((sl,i) => 
              (i % 2 === 1 ? <div className="hplink_item" key={String.fromCharCode(FirstLetterCharCode+i)} ><a href={sl.social_link} target="_blank" title={sl.social}><i className={sl.social_icon} aria-hidden="true"></i>{sl.social}</a></div> : "" )
            )}
          <div className="hplink_item" key="z"><a href={'mailto:' + props.parkEmail} title="Email"><i className="lni lni-envelope" aria-hidden="true"></i> Email</a></div>                                                            
        </QueueAnim>
      </OverPack>

    );
};

export default Part02;