import React, { useState, useEffect  } from 'react';
import './App.css';
import DevBy from './components/DevBy';
import Main from './components/Main';
import Meta from './components/Meta';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from "react-i18next";
import useFetch from "react-fetch-hook";
//import ReactGA from 'react-ga';
//import { useDirectus } from 'react-directus'
import { createDirectus, rest, graphql,staticToken, readCollection, readItems  } from '@directus/sdk';


import {BallTriangle} from 'react-loader-spinner'; //https://mhnpd.github.io/react-loader-spinner/docs/intro

function App (props){
  var IO = this;
  const {i18n } = useTranslation('base');
  const [menuISopen, set_menuISopen] = useState(false);
  const myLang = i18n.language; //"it-IT"

  //ReactGA.pageview("/" + myLang + "");  
  //ReactGA.pageview(window.location.pathname);
// ----------------------------------------------------------------------------------------------------------



  // ----------------------------------------------------------------------------------------------------------
  function togglemenuClick(forceclose) {
    const mio = menuISopen;
    if (mio || forceclose){
      document.body.classList.remove('menu-open');
      set_menuISopen(false);
    }else{
      document.body.classList.add('menu-open');
      set_menuISopen(true);
    }
  }
  // ----------------------------------------------------------------------------------------------------------
  document.addEventListener('keydown', function(event){
    if(event.key === "Escape"){ togglemenuClick(true); }
  }); 
  // ----------------------------------------------------------------------------------------------------------
   let url = process.env.REACT_APP_U;
   const directus =  createDirectus(url).with(staticToken(process.env.REACT_APP_T)).with(rest()); //graphql() //rest()
   

   //const client = createDirectus('http://directus.example.com').with(authentication());
  /*
//await client.login(email, password);
  async function initDirectus() {
    let authenticated = false;
    let authenticatedFail = 0;
    let authenticatedRetry = 5;


    while (!authenticated && authenticatedFail<=authenticatedRetry) {
      await directus.auth
        .static(process.env.REACT_APP_T)
        .then(() => {
          console.log('Valid credentials!');
          authenticated = true;
        })
        .catch(() => {
          console.log('Invalid credentials');
          authenticatedFail +=1;
        });
    }
    //await Promise.resolve( directus.auth.static(process.env.REACT_APP_ACCESSTOKEN) );
  }
  /////////////////initDirectus();


  useEffect(() => {
    const fetchPark = async () => {
      //const park = (await directus.items('parks').readOne().readMany()).data;
      const park = (
        await directus.items("parks").readByQuery({
          //search: 'parks',
          fields: ['*,langs.languages_code,txt_park.*'],
          txt_park: {
            filter: {
              languages_code : {_starts_wit: myLang}
            },
            "comments": {
              "_sort": "rating",
              "_limit": 1
            }
          }
        })
        );
        //const park2 = await directus.items("parks").readOne("22");
      //set_inLoading(false)
      //set_dParc({ dDatas: park });
    };
    fetchPark();
  }, [directus]);
  */
  /////////////////////////////////////////////////////////



  const res01 = useFetch(process.env.REACT_APP_SERVERURL + "parks/?" + process.env.REACT_APP_ACCESSTOKEN
  + "&fields=*,langs.languages_code,txt_multilang.*" 
  + "&deep[txt_multilang][_filter][languages_code][_starts_with]="+ myLang);

  const res02 = useFetch(process.env.REACT_APP_SERVERURL + "pages/?" + process.env.REACT_APP_ACCESSTOKEN
  + "&fields=txt_multilang.*,txt_multilang.page_area.*" 
  + "&deep[txt_multilang][_filter][languages_code][_starts_with]="+ myLang);

  if (res01.isLoading || res02.isLoading) return (
    <div className="alcentro">
      <BallTriangle color="#5b7356" loading="l o a d i n g . . ."  height={150} width={150} radius={5} />
    </div>
    )
  if(res01.error || res02.error) return (
    <>
      <b>E R R O R E</b>      
      <DevBy/>
    </>
  )
  const park = res01.data.data[0];
  const page = res02.data.data[0];

  return (
    <>
      <Meta parkName={park.name} pageInfo={page.txt_multilang[0]} />
      <Main togglemenuClick={()=> togglemenuClick()} parkInfo={park} pageTxtBlocks={page.txt_multilang}/>      
      <DevBy/>
    </>
  )
}


/*
    <Main togglemenuClick={()=> togglemenuClick()} parkInfo={data} pageTxtBody={'ccc'}/>
      <Meta parkName={data.parks[0].name} pageInfo={data.pages[0].txt[0]} />
      <Main togglemenuClick={()=> togglemenuClick()} parkInfo={data.parks[0]} pageTxtBody={data.pages[0].txt[0].body}/>

      useEffect(() => 
  document.AAA = 'enttro';
  fetch('https://adm.parkbi.com/items/parks/05f49292-05bc-4d13-bc02-f0c6b0bfdfee?access_token=sfdad-SAF5Y-12324-SDHJI-qewlpx&fields=*,langs.languages_code')
  .then(res => res.json()){
  .then((data) => {
    set_dData({ dDatas: data });
    set_fechState('D');
  })
  .catch(console.log)
  .finally(set_inLoading(false))
});
*/




export default App;
