import React from 'react';
import { useTranslation } from "react-i18next";



function Header(props) {
    //document.AA = 'ss' + props.w3w + 'dd';
    const { t,i18n } = useTranslation('base');
    const altLang = i18n.options.supportedLngs.map((l, i) => 
        (l===i18n.language || l==='cimode' ? "" : <li key={l}><a href={"/" + l + "/"} ><img src={process.env.PUBLIC_URL + '/img/flag/' + l + '.png'} border="0" alt={'flag_' + t(l +'_name')}/><span>&nbsp; {t(l +'_name')}</span></a></li> )
    );
    return (
            <header className="sitehead">
                <div className="sitehead__inner">
                        <ul className="toggleflagmenu">
                            <li><a href="#" ><img src={process.env.PUBLIC_URL + '/img/flag/' + i18n.language + '.png'} border="0" id="langBtn" alt={'flag_' + i18n.language}/></a></li>
                            {altLang}
                        </ul>                 
                    </div>                   
            </header>
        );
  }

export default Header;

/*
  
*/