import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider } from 'react-helmet-async';
import "./i18n";

/* GOOGLE Analytics  ---------------------------------------------------------------- */
//https://github.com/react-ga/react-ga
 //import ReactGA from "react-ga";
 //ReactGA.initialize('UA-207061668-1');
/* END -  GOOGLE Analytics  ---------------------------------------------------------------- */

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <App/>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>
*/

//serviceWorker.unregister(); //??????????????????????????????????????????????????????????????????'