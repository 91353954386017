export const base_it = {
  /*
  meta_tit: "Parco Nationale d'Ifrane",
  meta_dsc: "Il solo e unico parco avventura all'interno del Parco Nazionale d'Ifrane in Marocco, in un oasi di natura tra i maestosi cedri e in compagnie delle simpatiche e irriverenti scimmie.",
  meta_key: "Parco Avventura,Ifrane,Atlas,Marocco,Natura,Sport,Ecoturismo,Albering,Parc Aventure,Rope Course, Adventure Park",
  home_dsc: {
    part1: "Parco Avventura, Albering, Parc Aventure, Rope Course,  Adventure Park... ",
    part2: "Tanti nomi per un unico concetto: emozioni sugli alberi nella natura in piena sicurezza!",
  },
  what3words:{
    w1: "fanali",
    w2: "miraggi",
    w3: "quotidiani",
  },
  */
};