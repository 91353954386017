import React, { Component }  from 'react';
import parse from "html-react-parser";

const Footer = (props) => {
  return (
      <footer >
        <div >{parse(props.txt)}</div>
        <div className="cell"><a href={'tel:'+ (props.phone).replaceAll(' ','').replaceAll('.','')}>
        <i className="lni lni-phone" aria-hidden="true" style={{verticalAlign: 'top'}}></i>
          {props.phone}</a> </div>
      </footer>
  );
};
export default Footer;