export const base_fr = {
  /*
  meta_tit: "Parc National d'Ifrane",
  meta_dsc: "Le seul parc accrobranche situé dans le Parc National d'Ifrane au Maroc, dans une oasis de nature entre les cèdres majestueux et en compagnie de singes jolies et irrévérencieux.",
  meta_key: "Parc Accrobranche,Ifrane,Atlas,Maroc,Nature,Sport,Ecotourisme,Albering,Parc Aventure,Rope Course,Adventure Park",
  home_dsc: {
    part1: "Parc Accrobranche, Albering, Parc Aventure, Rope Course,  Adventure Park... ",
    part2: "De nombreux noms pour un même concept : des émotions sur les arbres en pleine nature en toute sécurité !",
  },
  what3words:{
    w1: "frondaison",
    w2: "maquiller",
    w3: "comptable",
  },
  */
};